(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/base/module-base-assets/bootstrap-application.js') >= 0) return;  svs.modules.push('/components/tipsen/base/module-base-assets/bootstrap-application.js');
"use strict";

const {
  init
} = svs.components.tipsen.base;

const appRoot = document.getElementById('tipsen');
init(appRoot);

 })(window);